<template>
	<div>
		<div class="main">
			<img src="@/assets/logo.png" alt="logoLiderMed">
			<a href="https://lidermed.com.ua/">відвідати сайт ЛідерМед</a>
		</div>
	</div>
</template>
<style lang="sass" scoped>
.main
	position: relative
	margin: 3em 0
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	img
		width: 200px
		margin-bottom: 25px
	a
		padding: 10px 15px
		font-weight: 600
		background-color: #2ecc71
		color: #fff
		border: none
		// text-transform: uppercase
		border: 1px solid rgba(#bdc3c7, 0.25)
		border-radius: 5px
		margin: 0 5px
		box-shadow: 0 10px 15px 5px rgba(0,0,0,0.05)
		cursor: pointer
		text-decoration: none
		&:hover,
		&:focus
			background-color: darken(#2ecc71, 5%)
</style>