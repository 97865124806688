<template>
	<div>

		<div class="container">
			<div class="logo">
				<a href="https://lidermed.com.ua/" title="Перейти на сайт"><img src="@/assets/logo.png" alt="lidermed logo"></a>
			</div>
			<component v-if="!submitSucces" :is="activeBlock" :formData="formData" @submit="submitForm"></component>
			<Success v-else></Success>
			<ProgressBar :activeBlock="activeBlock" :count="1"></ProgressBar>
		</div>
	</div>
</template>
<script>
import CompanyFormStep1 from '@/components/CompanyForm/CompanyFormStep1.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import Success from '@/components/Success.vue'
export default {
	components: {
		ProgressBar,
		CompanyFormStep1,
		Success
	},
	data() {
		return {
			activeBlock: "CompanyFormStep1",
			submitSucces: false,
			formData: {}
		}
	},
	mounted() {
		// const params = new URLSearchParams(window.location.search);
		// this.formData.location = params.get('loc');
		this.formData.location = this.$route?.query?.loc || null;
	},
	methods: {
		async submitForm(data) {
			// console.log('subm')
			this.formData.formname = 'companyanon'
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(this.formData)
			};
			const response = await fetch("api.php", requestOptions);
			const datas = await response.json();
			if (datas.error.length > 0) {
				console.log(datas.error);
			} else {
				if (datas.data == 1) {
					this.submitSucces = true
					this.activeBlock = 'Step1'
					// this.formData = {}
				}
			}



		}
	}
}
</script>
<style lang="sass" scoped>
.logo
	text-align: center
	margin: 1em 0
	img
		width: 150px
</style>